
.soft-wrap {
    margin-top: 18px;
    height: calc(100% - 18px);
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    .soft-item {
        background: #FFFFFF;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
        width: 385px;

        .item-content {
            padding: 5px 6px 18px;

            .top-content {
                height: 160px;
                position: relative;

                &:hover {
                    .hover-box {
                        opacity: 1;
                    }
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                .hover-box {
                    position: absolute;
                    display: flex;
                    top: 6px;
                    right: 10px;
                    opacity: 0;

                    .hover-item {
                        width: 40px;
                        height: 40px;
                        background: #FFFFFF;
                        border: 1px solid #EEEEEE;
                        opacity: 0.85;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;

                        &.edit {
                            color: #2461EF;
                        }

                        &.delete {
                            color: #FF0000;
                            margin-left: 18px;
                        }
                    }
                }
            }

            .bottom-content {
                cursor: pointer;
                margin-top: 12px;

                .bottom-title {
                    font-size: 18px;
                    color: #333333;
                }

                .bottom-text {
                    color: #999999;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    ::v-deep p {
                        img {
                            display: none;
                        }
                    }
                }

                .bottom-info {
                    color: #666666;
                    display: flex;
                    justify-content: space-between;

                    .info-right {
                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }

        }
    }

    .no-data {
        width: 478px;
        height: 203px;
        background: #E7F6EF;
        border: 1px solid #2DC079;
        border-radius: 4px;
        position: relative;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .data-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            i {
                font-size: 30px;
                color: #2DC079;
            }

            .show-title {
                margin-top: 20px;
                font-size: 18px;
                color: #666666;
            }
        }
    }
}
